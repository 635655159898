html {
  width: 100vw;
  height: 100%;
  /* overflow: hidden; */
  color-scheme: dark;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Rajdhani', sans-serif;
  /* overflow: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../img/2.png");
  background-position: center;
  color: white;
}

#root {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.login-form {
  position: absolute;
  left: 0;
  right: 0;

  box-sizing: border-box;
  padding: 30px;
  background-color: #0808081f;
  border-radius: 40px;
  backdrop-filter: blur(7px);
  border: 1px #2f2f2f solid;
  box-shadow: 0 0 5px black;

  margin-top: 50vh;
  margin-left: auto;
  margin-right: auto;
  width: 650px;
  height: 500px;
  transform: translateY(calc(-50% - 50px));
}

#title-notloggedin {
  overflow: hidden;
  position: absolute;
  height: 0;
  left: 0;
  right: 0;
}

#title-notloggedin > span {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 32px;
}

#title-loggedin {
  font-size: 42px;
  height: 55px;
  width: 355px;
  margin-top: 25px;
  margin-bottom: 20px;
  overflow: hidden;
  margin-left: calc(50vw + 40px);
}

#title-loggedin > span {
  display: inline-block;
  transform: translate(-100%);
}

#magonsky-industries {
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50vw;
}

#magonsky-industries .cls-1 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.bigger-stroke > * {
  stroke-width: 4px !important;
}

@keyframes dashes {
  to {
      stroke-dashoffset: 0;
  }
}

.main-container {
  width: 80vw;
  height: calc(100vh - 110px);
  box-sizing: border-box;
  margin: auto;
  padding-bottom: 30px;
}

.panel {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  background-color: #0808081f;
  border-radius: 40px;
  backdrop-filter: blur(7px);
  border: 1px #2f2f2f solid;
  box-shadow: 0 0 5px black;
  display: flex;
  flex-direction: column;

  transition: 200ms;
  opacity: 0;
}

.flex-fill-rest {
  flex: 1;
  overflow-y: auto;
}

.header {
  font-size: 40px;
  margin: 10px auto;
}

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0004;
}

.modal-ctn {
  width: 600px;
  height: 400px;
  box-sizing: border-box;
  padding: 30px;
  background-color: #08080876;
  border-radius: 40px;
  backdrop-filter: blur(7px);
  border: 1px #2f2f2f solid;
  box-shadow: 0 0 5px black;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loan {
  background-color: #ffffff0e;
  border-radius: 10px;
  margin: 10px;
}

.loan-info {
  padding: 15px;
  border-radius: 10px;
  display: flex;
}

.loan-info.select {
  background-color: #ffffff0e;
}

.loan-info-item {
  flex: 1;
}

.loan-title {
  margin: 0;
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: bold;
}

.loan-desc {
  margin: 0;
  margin-left: 5px;
}

@media only screen and (max-width: 700px) {
  #title-notloggedin > span {
    font-size: 22px;
  }
  #title-loggedin {
    font-size: 24px;
    margin-top: 17px;
    width: 120px;
  }
}