.admin-buttons-ctn {
    text-align: center;
}

.back-button {
    padding: 10px;
    margin-top: 20px;
}

.admin-buttons-ctn button {
    margin: 20px;
    padding: 10px;
}

.loan-admin-icon {
    width: 50px;
    height: 50px;
    align-self: center;
    background-color: red;
}

.loan-admin-ctn {
    overflow: hidden;
    height: 0;
    transition: 200ms;
}

.loan-admin-ctn.show {
    height: 130px;
}

.loan-admin-ctn .controls {
    padding: 15px;
}

.loan-admin-ctn form {
    display: flex;
    flex-flow: wrap;
}

.loan-admin-ctn .item {
    height: 100px;
    width: 200px;
    padding: 10px;
    box-sizing: border-box;
}

.loan-admin-ctn .item > p {
    margin-top: 10px;
    margin-bottom: 10px;
}

.loan-admin-ctn .item > input[type="text"] {
    width: 150px;
    padding: 5px;
}

.loan-admin-ctn .item.right {
    margin-left: auto;
}

@media only screen and (max-width: 700px) {
    .loan-admin-ctn .item-break {
        width: 100%;
    }
}
